@import "../../../css/utility.scss";

td {
  height: 32px !important;
  padding: 7px 5px !important;
  border-bottom: 0 !important;
}
.table-style {
  overflow: scroll;
  th {
    background-color: #ececec;
    min-width: 250px;
  }
  td,
  th {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }
}
.table-disable {
  background-color: #ececec;
  color: #b9b9b9;
}

.MuiTableRow-root {
  outline: 1px solid #ececec !important;
}
.addRow-dataMap {
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  padding: 0 5px !important;
  // background: #f5f5f5;
}
.reset-data {
  border-radius: 4px !important;
  height: 36px;
  color: #202020 !important;
  border: 1px solid #202020 !important;
  // margin-bottom: 0 !important;
  // margin-right: 15px!important;
  // margin-top: 5px!important;
  margin: 5px 15px 0 10px !important;
}
.DataMapTable {
  display: flex !important;
  .MuiGrid-item {
    .label {
      background: white;
    }
  }
  .range-selector {
    align-items: center !important;
  }
  &:first-child {
    position: sticky !important;
    top: 0 !important;
    z-index: 44;
    .MuiPaper-root {
      border-top: 1px solid #7c7c7c;
      justify-content: center;
      align-items: center;
      background: #ececec !important;
    }
    .label {
      // padding: 0 8px;
      max-width: 90px;
    }
  }
  .MuiPaper-root {
    border-bottom: 1px solid #7c7c7c;
    border-right: 1px solid #7c7c7c;
    border-radius: 0;
    display: flex;
    font-size: 14px !important;
    padding: 0 !important;
    position: relative;
    min-width: 100px;
    width: 100px;
    word-break: break-all;
    align-items: center;
    &.DataMap-header {
      padding: 6px 4px !important;
    }
    .MuiIconButton-root {
      padding: 0 !important;
      position: absolute;
      right: 0;
      top: 2px;
    }
    &:first-child {
      // border-top: 1px solid #7c7c7c;
      border-left: 1px solid #7c7c7c;
    }
  }
  .MuiSelect-root,
  .MuiOutlinedInput-root,
  .MuiMenuItem-root {
    font-size: 14px !important;
    height: 100%;
    padding: 0 0 !important;
    input {
      height: 92%;
      padding: 0 6px !important;
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border: 0 !important;
  }
  .datatype-wrap {
    width: 90px;
    align-items: center;
    .MuiSelect-icon {
      right: -2px !important;
      top: calc(50% - 0.4em) !important;
    }
    select {
      height: 100%;
      border: 0;
    }
  }
  .rangeSelect-wrap {
    display: flex;
    width: 100px;
    align-items: center;
    justify-content: center;
    .MuiInput-input {
      min-width: 60px;
      padding: 0 !important;
      font-size: 12px;
      line-height: 16px;
    }
    .MuiTextField-root {
      height: 100%;
      justify-content: center;
      font-size: 12px;
    }
  }
}
.DataMapTable-wrap {
  overflow: scroll;
  height: 100%;
  // padding: 5px 5px;
  flex-wrap: nowrap !important;
  flex-direction: column !important;
  &.prePocessor {
    height: calc(100vh - 269px) !important;
    position: relative;
  }
  .readMore-btn {
    padding: 0;
    position: absolute;
    right: -3px;
    height: 20px;
    cursor: pointer;
    top: 0;
    border: 0;
    background-color: transparent;
    .MuiSvgIcon-root {
      width: 18px !important;
      height: 18px !important;
    }
  }
  select:disabled {
    border-width: 0 !important;
    border-bottom: 0 !important;
  }
  .MuiOutlinedInput-input {
    padding: 8px 5px !important;
    line-height: 16px;
    font-size: 12px;
  }
  .MuiTableCell-root {
    // min-width: 114px !important;
    border-bottom: 0 !important;
    padding: 9px 5px !important;
    padding: 12px 5px !important;
  }
  .MuiPaper-root {
    box-shadow: none !important;
    align-items: flex-start;
    // &:nth-child(2){
    //   margin-left: 126px;
    // }
    .textfieldClass {
      width: 45px;
      padding-left: 4px;
      border: 0;
      background-color: transparent;
    }
    textarea {
      border: 0 !important;
      resize: none;
      overflow: hidden;
      height: 100%;
      padding-right: 12px;
      &:focus-visible {
        outline: 0px !important;
        border-bottom: 2px solid #1976d2 !important;
      }
    }
  }
}
.container-2 {
  .DataMapTable-wrap {
    &.prePocessor {
      height: calc(100vh - 62px) !important;
      position: relative;
    }
  }
}
.datatype-wrap:first-child {
  margin-left: 100px;
  .MuiOutlinedInput-input {
    // padding: 8px 5px !important;
    // line-height: 16px;
    cursor: pointer;
  }
}
.datatype-wrap {
  .disableCell {
    cursor: pointer;
    // opacity: 1.9;
    color: #000000a3 !important;
  }
  .disable_opacity {
    // color: #000000a3 !important;
    cursor: pointer;
  }
}
.DataMap-header {
  font-weight: 700 !important;
  background: #ececec !important;
  min-height: 50px;
  padding: 7px 0 !important;
  justify-content: center;
  left: 0;
  position: sticky !important;
  top: auto;
  z-index: 42;
  min-width: 100px;
  width: 100px !important;
  line-height: 14px !important;
  align-items: center !important;
  .label {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.6);
  }
}

.parameters-name {
  // font-weight: $font-bolder !important;
  background: #ececec !important;
  // min-height: 40px;
  height: 100%;
  // padding: 0px 0 !important;
  // justify-content: center;
  // left: 0;
  // position: sticky !important;
  // top: auto;
  // z-index: 42;
  // min-width: 100&;
  // width: 100% !important;
  // line-height: 14px !important;
  // align-items: center !important;

  // .label {
  //   font-weight: $font-bolder;
  //   color: rgba(0, 0, 0, 0.6);
  // }
  // .textarea {
  //   line-height: 10px !important;
  // }
}
.container-1 {
  height: calc(100vh - 226px) !important;
}
// Add Parameter Section Css

.parameter-add {
  padding: 0;
  margin-bottom: 15px;
  align-items: center;
  .MuiOutlinedInput-input {
    padding: 8.5px 14px !important;
    font-size: 14px;
  }
  .MuiPaper-root {
    box-shadow: none !important;
  }
}
.btn-add-para {
  background-color: rgba(0, 0, 0, 0.7) !important;
  padding: 10px;
  &.Mui-disabled {
    background-color: grey !important;
    color: #fff !important;
  }
}
// Add Parameter Section Css
// Row Reject css
.header-div {
  min-height: 50px;
}
.sub-row {
  min-height: 36px;
  height: 100%;
  display: flex;
  align-items: center;

  .validatior {
    padding: 5px;
    border-bottom: none;
  }
}

.checkbox-wrap {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 45px;
  input[type="checkbox"] {
    accent-color: rgb(255, 134, 134);
  }
  .MuiCheckbox-root {
    padding: 5px 9px !important;
    &:hover {
      background-color: transparent !important;
    }
  }
}
// Validator scss
.btn-validate {
  background-color: $secondary-theme-color !important;
  color: #fff !important;
  font-size: 12px !important;
  padding: 4px !important;
  margin: 4px !important;
  min-width: 82px !important;
  &.Mui-disabled {
    color: #fff !important;
    background-color: gray !important;
  }
}
.validator-edit {
  position: relative;
  display: flex;
  svg {
    // margin-top: 7px;
    cursor: pointer;
  }
  .validator-lable-wrap {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 24px;
    top: 0;
  }
  .label {
    // position:absolute!important;
    top: 1px !important;
    left: 30px !important;
    font-size: 12px !important;
    line-height: 16px !important;
    min-width: 130px;
    .MuiSvgIcon-root {
      font-size: 20px !important;
      margin: 4px;
    }
  }
}
.MuiSvgIcon-root {
  &.viewfull-screen {
    width: 26px !important;
    color: #202020;
    height: 26px !important;
  }
}
.add_new_parameter {
  margin: 1px 0 0px auto !important;
  height: 36px;
  background-color: rgba(0, 0, 0, 0.7) !important;
  padding: 7px 16px !important;
}
.add-new-para-modal {
  .MuiSvgIcon-root {
    width: 25px !important;
    height: 25px !important;
  }
}
.error-alert.MuiAlert-root {
  background-color: rgb(253, 237, 237) !important;
  color: rgb(95, 33, 32) !important;
  .MuiAlertTitle-root {
    color: rgb(95, 33, 32) !important;
    font-weight: 700;
  }
  .MuiAlert-icon {
    color: rgb(211, 47, 47) !important;
  }
}
.val-btn {
  padding: 0 16px;
}
.checkbox-wrap {
  .MuiCheckbox-root.Mui-checked {
    .MuiSvgIcon-root {
      color: rgb(255, 137, 116) !important;
    }
  }
}
